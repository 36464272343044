<template>
  <div class="intro-animatie">
    <img src="@/assets/images/icons/info.svg" />
  </div>
</template>
<style lang="scss" scoped>
.intro-animatie {
  cursor: pointer;
  width: 49px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include desktopxl-up {
    width: 89px;
    height: 80px;
  }
  background-color: white;
  border-radius: 50%;
  position: absolute;
  bottom: 60px;
  right: 2%;
  filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.12));
  z-index: 9999;
  img {
    position: absolute;
    width: 28px;
    @include desktopxl-up {
      width: 40px;
    }
  }
  &:before {
    content: "Begin hier";
    color: white;
    position: absolute;
    width: 200px;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    top: 104px;
    left: 50%;
    transform: translate(-50%, -50%);
    @include desktopxl-down {
    top: 64px;
      font-size: 16px;
    }
  }
}
</style>
